export type Status = {
  time: number;
  pitch: number;
  note: string;
  volume: number;
};

export class Analyzer {
  private calibrationData: {
    volume: number;
    pitch: number;
    threshold: number;
    rmsLimit: number;
  } | null = null;

  private context: AudioContext | null;
  private source: MediaStreamAudioSourceNode | null;
  private analyserNode: AnalyserNode | null;

  private readonly sampleRate: number;
  private readonly volumeBuffer: Uint8Array;
  private readonly buffer: Float32Array;

  private intervalLength = 50;
  private interval: any;

  private calibrationProgress: number = 0;

  public onCalibrationProgress?: (progress: number) => void;

  async startCalibration(
    cb: (progress: number) => void,
  ): Promise<{
    volume: number;
    pitch: number;
    threshold: number;
    rmsLimit: number;
  }> {
    return new Promise((resolve, reject) => {
      let totalVolume = 0;
      let totalPitch = 0;
      let count = 0;
      let totalRms = 0;

      const listener = (data: { volume: number; pitch: number }) => {
        if (count >= 100) {
          const rms = Math.sqrt(totalRms / count);
          const threshold = (totalVolume / count) * 0.01;

          this.calibrationData = {
            volume: totalVolume / count,
            pitch: totalPitch / count,
            threshold: threshold,
            rmsLimit: rms * 0.01,
          };

          console.log("Calibration complete");
          console.log(`Volume: ${this.calibrationData.volume}`);
          console.log(`Pitch: ${this.calibrationData.pitch}`);
          console.log(`Threshold: ${this.calibrationData.threshold}`);
          console.log(`RMS Limit: ${this.calibrationData.rmsLimit}`);

          this.stop();
          resolve(this.calibrationData);
        } else {
          totalVolume += data.volume;
          totalPitch += data.pitch;
          totalRms += data.volume * data.volume;
          count++;

          this.calibrationProgress = (count / 100) * 100;
          cb(this.calibrationProgress);

          if (this.onCalibrationProgress)
            this.onCalibrationProgress(this.calibrationProgress);
        }
      };

      try {
        navigator.mediaDevices
          .getUserMedia({
            audio: {
              channelCount: 1,
              echoCancellation: true,
              sampleRate: this.sampleRate,
            },
            video: false,
          })
          .then(stream => {
            this.start(stream, listener);
          })
          .catch(error => {
            console.error("Failed to get user media for calibration", error);
            reject("Calibration failed: " + error);
          });
      } catch (error) {
        console.error("Error in startCalibration: ", error);
        this.stop();
        reject("Calibration failed: " + error);
      }
    });
  }

  applyCalibration(calibrationData: {
    volume: number;
    pitch: number;
    threshold: number;
    rmsLimit: number;
  }) {
    this.calibrationData = calibrationData;
    console.log("Applied calibration data:", calibrationData);
  }

  getCalibrationData() {
    return this.calibrationData;
  }

  async start(stream?: MediaStream, cb: (data: Status) => void = () => {}) {
    if (!this.context || this.context.state === "closed")
      this.context = new AudioContext();

    if (this.context.state === "suspended") await this.context.resume();

    if (!stream)
      try {
        stream = await navigator.mediaDevices.getUserMedia({
          audio: {
            channelCount: 1,
            echoCancellation: true,
            sampleRate: this.sampleRate,
          },
          video: false,
        });

        if (stream.getAudioTracks().length === 0)
          throw new Error("Stream contains no audio tracks.");
      } catch (error) {
        console.error("Failed to get audio stream:", error);
        alert("Failed to access microphone. Please check permissions.");
        return;
      }

    const audioTracks = stream.getAudioTracks();
    if (audioTracks.length === 0) {
      console.error("No audio tracks found in the stream.");
      return;
    }

    this.source = this.context.createMediaStreamSource(stream);
    this.analyserNode = this.context.createAnalyser();
    this.analyserNode.fftSize = 4096;
    this.analyserNode.minDecibels = -97;
    this.analyserNode.maxDecibels = 0;
    this.analyserNode.smoothingTimeConstant = 0.1;

    this.source.connect(this.analyserNode);

    let time = 0;
    this.interval = setInterval(() => {
      const volume = this.getVolume();
      const { pitch, note } = this.getPitch();

      //console.log(
      //  `Time: ${time}ms - Volume: ${volume}%, Pitch: ${pitch}Hz, Note: ${note}`,
      //);

      cb({ pitch, note, time, volume });
      time += this.intervalLength;
    }, this.intervalLength);
  }

  async stop() {
    try {
      if (this.context?.state !== "closed") await this.context?.close();
    } catch (error) {
      console.error("Error closing AudioContext: ", error);
    }

    if (this.source) {
      const tracks = this.source.mediaStream.getTracks();
      tracks.forEach(track => track.stop());
    }

    this.source = null;
    this.analyserNode = null;
    clearInterval(this.interval);
  }

  getPitch() {
    this.analyserNode?.getFloatTimeDomainData(this.buffer);
    const pitch = autoCorrelate(
      this.buffer,
      this.context?.sampleRate || this.sampleRate,
    );

    if (pitch < 30 || pitch > 590) return { pitch: -1, note: "N/A" };

    const note = pitch > 0 ? noteFromPitch(pitch) : "N/A";

    //if (this.calibrationData && pitch < this.calibrationData.rmsLimit)
    //  return { pitch: 0, note: "N/A" };

    return { pitch, note };
  }

  getVolume() {
    this.analyserNode?.getByteFrequencyData(this.volumeBuffer);
    const volume =
      this.volumeBuffer.reduce((cum, cur) => cum + cur, 0) /
      (this.sampleRate / 2) /
      127;

    //if (this.calibrationData && volume < this.calibrationData.threshold)
    //volume = 0;

    return Math.round(volume * 100);
  }

  constructor(sampleRate = 2048) {
    const AudioContextClass =
      window.AudioContext || (window as any).webkitAudioContext;
    this.context = new AudioContextClass();
    this.source = null;
    this.analyserNode = null;
    this.sampleRate = sampleRate;
    this.volumeBuffer = new Uint8Array(sampleRate / 2);
    this.buffer = new Float32Array(sampleRate);
  }
}

/**
 * Calculates the auto-correlation of an input buffer.
 * @param {Float32Array} buffer - The input buffer.
 * @param {number} sampleRate - The sample rate of the audio signal.
 * @returns {number} The calculated auto-correlation.
 *   Returns -1 if there is not enough signal.
 */
const autoCorrelate = (buffer: Float32Array, sampleRate: number): number => {
  let bufferSize = buffer.length;
  let rootMeanSquare = 0;

  // Berechne RMS und wende Schwellenwert an
  for (let i = 0; i < bufferSize; i++) rootMeanSquare += buffer[i] * buffer[i];
  rootMeanSquare = Math.sqrt(rootMeanSquare / bufferSize);

  if (rootMeanSquare < 0.01) return -1;

  // Anwenden des Low-Pass-Filters mit 600Hz Cutoff
  //const filteredBuffer = lowPassFilter(buffer, sampleRate, 500);

  let lowerBound = 0,
    upperBound = bufferSize - 1;
  const threshold = 0.2;

  // Bestimme die relevanten Grenzen des Signals basierend auf dem Threshold
  for (let i = 0; i < bufferSize / 2; i++)
    if (Math.abs(buffer[i]) < threshold) {
      lowerBound = i;
      break;
    }

  for (let i = 1; i < bufferSize / 2; i++)
    if (Math.abs(buffer[bufferSize - i]) < threshold) {
      upperBound = bufferSize - i;
      break;
    }

  // Beschränke den Puffer auf den relevanten Bereich
  const slicedBuffer = buffer.slice(lowerBound, upperBound);
  bufferSize = slicedBuffer.length;

  const autoCorrelation = new Array(bufferSize).fill(0);

  // Berechne die Autokorrelationswerte
  for (let i = 0; i < bufferSize; i++)
    for (let j = 0; j < bufferSize - i; j++)
      autoCorrelation[i] += slicedBuffer[j] * slicedBuffer[j + i];

  // Glättung der Autokorrelationsdaten
  for (let i = 1; i < autoCorrelation.length - 1; i++)
    autoCorrelation[i] =
      (autoCorrelation[i - 1] + autoCorrelation[i] + autoCorrelation[i + 1]) /
      3;

  // Finde das erste Minimum
  let index = 0;
  while (autoCorrelation[index] > autoCorrelation[index + 1]) index++;

  // Finde das Maximum nach dem ersten Minimum
  let maximumValue = -1,
    maximumPosition = -1;
  for (let i = index; i < bufferSize; i++)
    if (autoCorrelation[i] > maximumValue) {
      maximumValue = autoCorrelation[i];
      maximumPosition = i;
    }

  let period = maximumPosition;

  // Parabolische Interpolation zur Feinanpassung
  const previousValue = autoCorrelation[period - 1] || 0;
  const currentValue = autoCorrelation[period] || 0;
  const nextValue = autoCorrelation[period + 1] || 0;

  const coefficientA = (previousValue + nextValue - 2 * currentValue) / 2;
  const coefficientB = (nextValue - previousValue) / 2;
  if (coefficientA) period = period - coefficientB / (2 * coefficientA);

  // Frequenz berechnen
  return sampleRate / period;
};

/*function autoCorrelate(buffer: Float32Array, sampleRate: number) {
  // Perform a quick root-mean-square to see if we have enough signal
  let SIZE = buffer.length;
  let sumOfSquares = 0;
  for (let i = 0; i < SIZE; i++) {
    const val = buffer[i];
    sumOfSquares += val * val;
  }
  const rootMeanSquare = Math.sqrt(sumOfSquares / SIZE);
  if (rootMeanSquare < 0.01) return -1;

  // Find a range in the buffer where the values are below a given threshold.
  let r1 = 0;
  let r2 = SIZE - 1;
  const threshold = 0.2;

  // Walk up for r1
  for (let i = 0; i < SIZE / 2; i++)
    if (Math.abs(buffer[i]) < threshold) {
      r1 = i;
      break;
    }

  // Walk down for r2
  for (let i = 1; i < SIZE / 2; i++)
    if (Math.abs(buffer[SIZE - i]) < threshold) {
      r2 = SIZE - i;
      break;
    }

  // Trim the buffer to these ranges and update SIZE.
  buffer = buffer.slice(r1, r2);
  SIZE = buffer.length;

  // Create a new array of the sums of offsets to do the autocorrelation
  const c = new Array(SIZE).fill(0);
  // For each potential offset, calculate the sum of each buffer value times its offset value
  for (let i = 0; i < SIZE; i++)
    for (let j = 0; j < SIZE - i; j++) c[i] = c[i] + buffer[j] * buffer[j + i];

  // Find the last index where that value is greater than the next one (the dip)
  let d = 0;
  while (c[d] > c[d + 1]) d++;

  // Iterate from that index through the end and find the maximum sum
  let maxValue = -1;
  let maxIndex = -1;
  for (let i = d; i < SIZE; i++)
    if (c[i] > maxValue) {
      maxValue = c[i];
      maxIndex = i;
    }

  let T0 = maxIndex;

  const x1 = c[T0 - 1];
  const x2 = c[T0];
  const x3 = c[T0 + 1];

  const a = (x1 + x3 - 2 * x2) / 2;
  const b = (x3 - x1) / 2;
  if (a) T0 = T0 - b / (2 * a);

  return sampleRate / T0;
}*/

const noteStrings = [
  "C",
  "C#",
  "D",
  "D#",
  "E",
  "F",
  "F#",
  "G",
  "G#",
  "A",
  "A#",
  "B",
];

const noteFromPitch = (frequency: number) => {
  const noteNum = 12 * (Math.log(frequency / 440) / Math.log(2));
  const idx = Math.round(noteNum) + 69;
  return noteStrings[idx % 12];
};
